import React from 'react'
import { graphql } from 'gatsby'

import Page from '../templates/Page'

const Agb = () => (
  <Page title="AGB">
    <a href="https://nighttec.net/wp-content/uploads/2021/03/Terms-and-Conditions.pdf">
      {' '}
      PDF download
    </a>
    <p>
      <strong>Terms of Service</strong>
    </p>
    <p></p>
    <p>
      <strong>Contracting party</strong>
    </p>
    <p></p>
    <p>
      On the basis of these terms and conditions (AGB) comes between the
      customer and
    </p>
    <p>NightTec</p>
    <p></p>
    <p>Represented by Tom Heinze</p>
    <p>Address: Blankenburger Straße 45 13156 Berlin</p>
    <p>Tel: +49 176 462 921 69</p>
    <p>E-mail address: info@nighttec.net</p>
    <p></p>
    <p>Commercial Register: [Commercial Register]</p>
    <p>Commercial Register Number: [Commercial Register Number]</p>
    <p>VAT identification number: DE317287664</p>
    <p></p>
    <p>hereinafter referred to as provider, the contract concluded.</p>
    <p></p>
    <p>
      <strong>Contract</strong>
    </p>
    <p>
      This agreement governs the sale of new goods and services in the field of
      night vision equipment via the provider's online shop. Because of the
      details of the respective offer, reference is made to the product
      description of the offer page.
    </p>
    <p>
      All Purchasers ordering export-controlled products have to provide a
      completed EUC to NightTec in order to validate their purchases. At the
      time of order the NightTecs customer service will contact the Purchaser to
      provide the EUC form. Failure to comply will immediately void the order.
      The order amount will be refunded and order declined.
    </p>
    <p>Please find the EUC here.</p>
    <p>
      <strong> </strong>
    </p>
    <p>
      <strong>Conclusion</strong>
    </p>
    <p>
      The contract is concluded in electronic commerce via the shop system or
      via other means of distance communication such as telephone and e-mail.
      The offers shown represent a non-binding invitation to submit an offer by
      the customer order, which can then accept the provider. The order process
      for concluding the contract includes the following steps in the shop
      system:
    </p>
    <p></p>
    <p>
        Selection of the offer in the desired specification (size, color,
      number)
    </p>
    <p>  Put the offer in the shopping cart</p>
    <p>  Press the button 'order'</p>
    <p>  Enter the billing and delivery address</p>
    <p>  Selection of the payment method</p>
    <p>  Verification and processing of the order and all entries</p>
    <p>  Press the button 'order with costs'</p>
    <p></p>
    <p>
      Confirmation mail that orders have been received Orders can be placed next
      to the shop system via remote communication (telephone / e-mail), whereby
      the order process for the conclusion of the contract includes the
      following steps:
    </p>
    <p></p>
    <p>  Call the order hotline / send the order mail</p>
    <p>
        Confirmation mail that the order has been receivedWith the sending of
      the order confirmation, the contract is concluded.
    </p>
    <p></p>
    <p>
      <strong>Contract Duration</strong>
    </p>
    <p>The contract is concluded for an indefinite period.</p>
    <p></p>
    <p>
      <strong>Prices, Shipping costs, Return costs</strong>
    </p>
    <p>
      All prices are final and include the legal value added tax. In addition to
      the final prices depending on the shipping method other costs incurred,
      which are displayed before sending the order. If there is a right of
      withdrawal and is used by this, the customer bears the cost of the return.
    </p>
    <p>
      <strong>Terms of payment</strong>
    </p>
    <p>
      The customer has only the following options for payment: advance transfer,
      payment service (PayPal), credit card. Other payment methods are not
      offered and will be rejected.
    </p>
    <p>
      The invoice amount is to be transferred in advance to the account
      specified there after receipt of the invoice, which contains all details
      for the transfer and is sent by e-mail. Using a fiduciary / payment
      service provider allows the provider and customer to process the payment
      between each other. The fiduciary service / payment service provider
      forwards the payment of the customer to the provider. Further information
      can be found on the website of the respective fiduciary service / payment
      service provider. When paying by credit card, the customer must be the
      cardholder. The credit card will be charged after the goods have been
      shipped. The customer is obliged to pay or transfer the amount shown to
      the account indicated on the invoice within 14 days after receiving the
      invoice. Payment is due without deductions from the date of invoice. The
      customer comes only after reminder in default.
    </p>
    <p>
      <strong>Delivery terms</strong>
    </p>
    <p>
      The goods will be shipped immediately after receipt of payment. The
      dispatch takes place after 2 days at the latest. The entrepreneur
      undertakes to deliver on the 7th day after receipt of the order unless
      otherwise agreed. The standard delivery time is 2 days, unless it is an
      order that has to be manufactured first. The entrepreneur will usually
      give you the delivery time in advance. The supplier either sends the order
      from its own warehouse as soon as the entire order is in stock there or
      the order is sent by the manufacturer as soon as the entire order is in
      stock there. The customer will be informed immediately of delays.
    </p>
    <p></p>
    <p>
      <strong>Warranty</strong>
    </p>
    <p>
      Consumers are entitled to the offered services a statutory warranty
      liability according to the relevant provisions of the Civil Code (BGB). If
      this is deviated, the warranty is based on the regulations set out in the
      General Terms and Conditions (GTC). If the customer is an entrepreneur,
      the warranty is excluded for second-hand goods. If the customer is a
      consumer, the warranty period for used goods is limited to one year. This
      does not apply to claims for damages of the customer due to injury to
      life, limb, health or essential contractual obligations, which must
      necessarily be fulfilled in order to achieve the contractual objective.
      Likewise, this does not apply to claims for damages after grossly
      negligent or intentional breach of duty by the provider or his legal
      representative or vicarious agent. Otherwise, the statutory provisions
      apply.
    </p>
    <p>
      <strong>Contracts</strong>
    </p>
    <p>
      The contract text is stored by the provider. The customer has the
      following possibility to access the stored contract text: About the shop
      system when ordering. This section can be found on the following page:
      www.NightTec.net. The customer can correct errors in the input during the
      ordering process. For this he can proceed as follows: About the shop
      system when ordering.
    </p>
    <p></p>
    <p>
      <strong>Right of withdrawal and customer service</strong>
    </p>
    <p>
      <strong>Cancellation</strong>
    </p>
    <p>Withdrawal</p>
    <p>
      You have the right to withdraw from this contract within fourteen days
      without giving any reason.
    </p>
    <p>The revocation period is fourteen days from the day,</p>
    <ul>
      <li>
        <p>
          In the case of a contract of sale: in which you or a third party named
          by you, who is not the carrier, has or has taken possession of the
          last goods.
        </p>
      </li>
      <li>
        <p>
          In the case of a contract for several goods ordered by the consumer
          under a single order and delivered separately: to which you or a third
          party named by you, who is not a carrier, has or has taken possession
          of the last good.
        </p>
      </li>
      <li>
        <p>
          In the case of a contract for the delivery of a good in several lots
          or pieces: where you or a third party named by you, who is not a
          carrier, has or has taken possession of the last partial shipment or
          the last.
        </p>
      </li>
      <li>
        <p>
          In the case of a contract for the regular delivery of goods for a
          fixed period of time: where you or a third party named by you, who is
          not a carrier, has or has taken possession of the first good. When
          several alternatives meet, the last time is decisive.
        </p>
      </li>
    </ul>
    <p>
      To exercise your right of withdrawal, you must (NightTec, Tom Heinze,
      Blankenburger Straße 45, 13156 Berlin +49 176 462 921 69
      info@nighttec.net) by means of a clear statement (eg a letter sent by
      mail, fax, or e-mail) inform about your decision to withdraw from this
      contract. You can use the attached model withdrawal form, which is not
      required.
    </p>
    <p>
      In order to maintain the cancellation period, it is sufficient for you to
      send the notification of the exercise of the right of withdrawal before
      the expiry of             the withdrawal period.
    </p>
    <p>
      <strong>Consequences of the cancellation</strong>
    </p>
    <p>
      In the case of an effective revocation the mutually received benefits are
      to be returned and if so derived profits (e.g. interest) are to be
      returned. In case the Purchaser is unable to reimburse NighTec either in
      total or partially for services received as well as any possible benefits
      gained (e.g. benefits of use) or return the item in a deteriorated
      condition only, the Purchaser has to offer a compensation of equal value.
      This only applies when the deterioration of the goods and the benefits
      gained can be attributed to handling of the goods that exceeds the
      examination of functions and properties of the goods. Examinations of
      functions and properties refers to testing and trying out of said goods as
      it is possible and common in a shop.
    </p>
    <p>
      For this repayment, we will use the same means of payment as you used in
      the original transaction, unless expressly agreed otherwise with you
    </p>
    <p>
      All night vision devices are made to order. The selected goods are not in
      stock and are ordered specifically for the buyer specified at the time of
      order. Please ensure that special requests and specifications are noted in
      your order confirmation or invoice.
    </p>
    <p>
      <strong> </strong>If, for any reason, the buyer cancels an order in whole
      or in part for a made-to-order item and unless otherwise agreed in
      writing, a cancellation fee equal to 25% of the invoice value will be paid
      by the buyer or deducted from refunds.
    </p>
    <p>
      If, for any reason, the buyer cancels an order in whole or in part for a
      non-order item and unless otherwise agreed in writing, a cancellation fee
      equal to 15% of the invoice value will be paid by the buyer or no refund
      will be made.
    </p>
    <p>
      When ordering one or more tubes, the following special feature applies.
      Tubes may be canceled with a cancellation fee (see item made to order) of
      25% of the invoice value unless opened from the original packing. Should
      they be opened by the customer, any refund is no longer possible.
    </p>
    <p>Cancellation fees for rented equipment are as follows:</p>
    <p>
      More than 14 days before the 1st day of the event: 0%
      <br /> 7-14 days before the 1st day of the event: 50%
      <br /> 0-7 days before the 1st day of the event: 90%
    </p>
    <p>
      A customer who can not present a valid ID when renting is not entitled to
      the device.
    </p>
    <p>
      You have the goods immediately and in any event not later than fourteen
      days from the date on which you inform us of the cancellation of this
      contract to NightTec, Tom Heinze, Blankenburger Straße 45, 13156 Berlin
      +49 176 462 921 69 info@nighttec.net us to be returned or handed over. The
      deadline is met if you send the goods before the deadline of fourteen
      days.
    </p>
    <p>You bear the immediate costs of returning the goods.</p>
    <p>
      You only have to pay for a possible loss in value of the goods, if this
      loss of value is due to a handling that is not necessary for the
      examination of the nature, characteristics and functioning of the goods.
    </p>
    <p>End of revocation</p>
    <p>Export/Import and Customs</p>
    <p>
      The sale and disposition of goods and software supplied by NightTec shall
      at all times be subject to the export control laws and regulations of
      Japan, the United States of America, the European Union, and its Member
      States and any amendments thereof. The Purchaser will not make any
      disposition of such goods or software other than into the ultimate country
      of destination specified by the Purchaser in writing and declared on
      NightTec's invoice. The Purchaser will not export any such goods or
      software or any part thereof including any technical data or the direct
      product in contravention of the applicable regulations of Japan, the
      United States of America, Canada, Australia, New Zealand, Norway,
      Switzerland, Great Britain, the European Union or its Member States.
    </p>
    <p>
      Every customer is instructed to inform himself about the applicable law of
      his country. NightTec cannot be held liable for violations of the
      applicable law in the respective country.
    </p>
    <p>
      <strong>Disclaimer</strong>
    </p>
    <p>
      Claims for damages of the customer are excluded, unless otherwise stated
      for the following reasons. This also applies to the representative and
      vicarious agents of the provider, if the customer raises these claims for
      damages. Excluded are claims for damages of the customer due to injury to
      life, limb, health or essential contractual obligations, which must
      necessarily be fulfilled in order to achieve the contractual objective.
      Likewise, this does not apply to claims for damages after grossly
      negligent or intentional breach of duty by the provider or his legal
      representative or vicarious agent.
    </p>
    <p>
      <strong>Assignment and pledge ban</strong>
    </p>
    <p>
      Claims or rights of the customer against the provider may not be assigned
      or pledged without his consent, unless the customer has demonstrated a
      legitimate interest in the assignment or pledging.
    </p>
    <p>
      <strong>Language, jurisdiction and applicable law</strong>
    </p>
    <p>
      The contract is written in German, English. The further implementation of
      the contractual relationship takes place in German, English. It
      exclusively applies the law of the Federal Republic of Germany. For
      consumers, this applies only insofar as this does not restrict any
      statutory provisions of the state in which the customer has his domicile
      or habitual residence. The place of jurisdiction for disputes with
      customers who are not a consumer, a legal entity under public law or a
      special fund under public law is the registered office of the provider.
    </p>
    <p>
      <strong>Data protection</strong>
    </p>
    <p>
      In connection with the initiation, conclusion, settlement and reversal of
      a purchase contract based on these terms and conditions, the provider
      collects, stores and processes data. This happens in the context of the
      legal regulations. The provider does not disclose any personal data of the
      customer to third parties, unless he is legally obliged to do so or the
      customer has expressly previously consented. If a third party is used for
      services in connection with the processing of processing, the provisions
      of the Federal Data Protection Act are complied with. The data provided by
      the customer by way of order will only be processed to establish contact
      within the scope of the contract and only for the purpose for which the
      customer provided the data. The data will only be passed on as far as
      necessary to the shipping company, which takes over the delivery of the
      goods according to the order. The payment details will be forwarded to the
      bank responsible for the payment. Insofar as the provider meets retention
      periods of a commercial or tax nature, the storage of some data may take
      up to ten years. During the visit to the Internet shop of the provider,
      anonymized data that does not allow for inferences on personal data and
      also do not intend, in particular IP address, date, time, browser type,
      operating system and pages visited logged. At the request of the customer,
      the personal data will be deleted, corrected or blocked within the scope
      of the legal provisions. A free information about all personal data of the
      customer is possible. For questions and requests for deletion, correction
      or blocking of personal data as well as collection, processing and use,
      the customer may contact the following address:
      <br />
      NightTec,
      <br />
      Tom Heinze,
      <br />
      Blankenburger Straße 45,
      <br /> 13156 Berlin
      <br /> +49 176 462 921 69
      <br /> info@nighttec.net
    </p>
  </Page>
)

export default Agb

export const query = graphql`
  query ($language: String!) {
    i18nLocales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
